
import { defineComponent, ref } from "vue";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import SliderEditor from "@/cloudfun/components/SliderEditor.vue";

export default defineComponent({
  components: {
    SliderEditor
  },
  setup() {
    const date = ref("");
    const classicEditor = ClassicEditor;
    const simpleEditorConfig = {
      removePlugins: ["Markdown", "MediaEmbedToolbar", "ImageToolbar"],
      toolbar: {
        items: ["bold", "italic", "underline", "link"]
      }
    };
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin],
      removePlugins: ["Markdown", "MediaEmbedToolbar"],
      toolbar: {
        items: [
          "fontSize",
          "fontFamily",
          "fontColor",
          "fontBackgroundColor",
          "bold",
          "italic",
          "underline",
          // 'strikethrough',
          //   'code',
          // 'subscript',
          // 'superscript',
          "link",
          "undo",
          "redo",
          "imageUpload",
          "highlight"
        ]
      },
      image: {
        toolbar: ["imageTextAlternative", "imageStyle:side", "linkImage"]
      }
    };
    const standardEditorData = ref("<p>Content of the editor.</p>");
    const initDocumentEditor = (editor: any) => {
      const toolbarContainer = document.querySelector(
        ".document-editor__toolbar"
      );
      if (toolbarContainer)
        toolbarContainer.appendChild(editor.ui.view.toolbar.element);
      const win: any = window;
      win.editor = editor;
    };

    const sliderWidth = ref("100%");
    const bannerFormOptions = {
      titleWidth: 80,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true }
          }
        },
        {
          field: "LinkUrl",
          title: "鏈結網址",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入網址", clearable: true }
          }
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", max: 128 }],
        LinkUrl: [{ type: "string", max: 2000 }],
        Remark: [{ type: "string", max: 512 }]
      }
    };

    return {
      date,
      classicEditor,
      simpleEditorConfig,
      editorConfig,
      standardEditorData,
      initDocumentEditor,
      sliderWidth,
      bannerFormOptions
    };
  }
});
