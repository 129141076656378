<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">產品管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span>
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <!-- <a href="javascript:;" data-toggle="modal" data-target="#product-modal" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </a> -->
        <button
          v-show="!grid.isEditing && info.isVendor"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="
            () => {
              showProductImg = false;
              grid.addNewRow();
            }
          "
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-10 mt-10">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <!-- <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div> -->
          <!-- <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div> -->
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <!-- <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button> -->
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @edit="onGridEdit"
          @removeSelectedRows="hideBatchDropDown()"
        >
          <template #image_column="{ row }">
            <div v-if="row.previewArchiveID" class=" flex justify-center">
              <img
                width="200"
                height="200"
                :src="`${uploadAction}/${row.previewArchiveID}`"
                class=" my-2"
              />
            </div>
            <div v-else class=" flex justify-center">
              <img
                src="https://via.placeholder.com/1280x854"
                style="width:192px;height:128px"
                class=" my-2"
              />
            </div>
          </template>
          <template #switch="{row}">
            <div class="flex justify-center">
              <vxe-switch
                v-model="row.enable"
                @change="updateEnable(row)"
                open-label="上架"
                close-label="下架"
              ></vxe-switch>
            </div>
          </template>
          <template #operate="{ row }">
            <div class="flex justify-center">
              <button
                class="button rounded-full mr-1 bg-blue-600 text-white mr-2"
                title="編輯"
                @click.stop="grid.edit(row)"
              >
                <FontAwesome icon="highlighter" class="w-4 h-4" />
              </button>
              <button
                class="button rounded-full bg-red-600 text-white"
                title="刪除"
                @click.stop="grid.remove(row)"
              >
                <FontAwesome icon="trash" class="w-4 h-4" />
              </button>
            </div>
          </template>

          <template #column-edit="{ }">
            <div>
              <a
                href="javascript:;"
                data-toggle="modal"
                data-target="#product-modal"
                class="button rounded-full text-white bg-blue-600 mr-2 inline-flex items-center"
              >
                <EditIcon class="w-4 h-4" />
              </a>
              <a
                href="javascript:;"
                class="button rounded-full text-white bg-blue-300 mr-2 inline-flex items-center"
              >
                <EyeIcon class="w-4 h-4" />
              </a>
            </div>
          </template>
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="beforeSubmit(row, submit)"
            >
              <template #tab>
                <div class="tabs-layout">
                  <div
                    class="nav-tabs flex flex-col sm:flex-row sm:items-center  mr-16 pb-1"
                  >
                    <a
                      data-toggle="tab"
                      data-target="#productInfo"
                      href="javascript:;"
                      class="mb-2 mx-2 sm:pb-3 active"
                    >
                      產品資訊
                    </a>
                    <a
                      data-toggle="tab"
                      data-target="#productContent"
                      href="javascript:;"
                      class="mb-2 mx-2 sm:pb-3 "
                    >
                      產品介紹
                    </a>
                    <a
                      data-toggle="tab"
                      data-target="#productImg"
                      href="javascript:;"
                      class="mb-2 mx-2 sm:pb-3 "
                    >
                      產品圖片
                    </a>
                    <!-- <a
                      data-toggle="tab"
                      data-target="#productContact"
                      href="javascript:;"
                      class="mb-2 mx-2 sm:pb-3 "
                    >
                      指定聯絡窗口
                    </a> -->
                  </div>

                  <div class="tab-content">
                    <div
                      id="productInfo"
                      class="tab-content__pane p-3 border border-theme-5 active"
                    >
                      <vxe-form
                        ref="infoForm"
                        v-bind="InfoFormOptions"
                        :data="row"
                      >
                        <template #column-keyword="{ data }">
                          <div class=" text-base font-bold mb-3">全站分類</div>
                          <div class="flex items-center">
                            <div class="vxe-form--item-title text-right">
                              <span class="text-lg text-red-300 mr-1">*</span>
                              服務類別
                            </div>
                            <select-box
                              v-bind="servicesSelectorOptions"
                              v-model="data.categories"
                            />
                            <!-- <vxe-input class-name="mr-2" v-model="data.categories" v-bind="servicesSelectorOptions" placeholder="請輸入服務類別" type="text" clearable></vxe-input>
                            <vxe-select v-model="data.categories" v-bind="servicesSelectorOptions" placeholder="請選擇服務類別" clearable>
                              <vxe-option value="1" label="服務類別"></vxe-option>
                              <vxe-option value="2" label="服務類別"></vxe-option>
                            </vxe-select>
                            <vxe-button class="ml-2 w-20 px-0" content="+"></vxe-button> -->
                          </div>
                        </template>
                        <template #column-keyword2="{ data }">
                          <div class="flex items-center">
                            <div class="vxe-form--item-title text-right">
                              <span class="text-lg text-red-300 mr-1">*</span>
                              產品類別
                            </div>
                            <select-box
                              v-bind="categorySelectorOptions"
                              v-model="data.services"
                            />
                            <!-- <vxe-select
                              v-bind="categorySelectorOptions"
                              v-model="data.services"
                              placeholder="請選擇產品類別"
                              clearable
                            >
                              <vxe-option
                                value="1"
                                label="產品類別"
                              ></vxe-option>
                              <vxe-option
                                value="2"
                                label="產品類別"
                              ></vxe-option>
                            </vxe-select>
                            <vxe-input
                              class-name="ml-2"
                              v-model="data.services"
                              v-bind="categorySelectorOptions"
                              disabled
                              placeholder="請輸入自訂類別"
                              type="text"
                              clearable
                            ></vxe-input>
                            <vxe-button
                              class="ml-2 w-20 px-0"
                              content="+"
                            ></vxe-button> -->
                          </div>
                        </template>
                        <template #column-member="{ data }">
                          <select-box
                            v-bind="memberSelectorOptions"
                            v-model="data.contactAccountIDs"
                          />
                        </template>
                        <template #column-enable="{ data }">
                          <div class="flex items-center">
                            <span class="text-xs">停用</span>
                            <vxe-switch
                              v-model="data.enable"
                              open-label="啟用"
                              close-label="停用"
                            ></vxe-switch>
                            <span class="text-xs">啟用</span>
                          </div>
                        </template>
                        <template #column-isCommonContractItem="{ data }">
                          <div class="flex items-center">
                            <vxe-checkbox
                              v-model="data.isCommonContractItem"
                              content="共同供應契約品項"
                            ></vxe-checkbox>
                          </div>
                        </template>
                        <template #column-mediaUrl="{ data }">
                          <div class="flex items-center justify-end">
                            <button
                              type="button"
                              class=" text-blue-600 text-xs flex items-center"
                              @click="mediaError = true"
                            >
                              前台顯示錯誤
                              <HelpCircleIcon class="w-4 h-4 ml-2" />
                            </button>
                          </div>
                          <div class="flex items-center">
                            <div class="vxe-form--item-title text-right">
                              影音連結
                            </div>
                            <vxe-input
                              v-model="data.mediaUrl"
                              type="text"
                              clearable
                            ></vxe-input>
                          </div>
                        </template>
                        <template #column-photo-and-remark="{ data }">
                          <div class="flex">
                            <file-uploader
                              :ref="el => (uploader = el)"
                              :headers="headers"
                              :data="imageData"
                              id="user-photo-uri"
                              style="min-width: 192px; max-width: 192px; min-height: 128px; max-height: 128px;"
                              class="mr-10 mb-10"
                              mode="image"
                              :modelValue="data.imgUri"
                              :action="uploadAction"
                              :autoUpload="true"
                              :limitedWidth="1280"
                              :limitedHeight="854"
                              @update:modelValue="
                                value => {
                                  if (value?.archiveID) {
                                    data.previewArchiveID = value?.archiveID;
                                  } else if (value == 'delete')
                                    data.previewArchiveID = '';
                                }
                              "
                              @filter="uploaderFilter"
                            />
                            <!-- <vxe-textarea
                              placeholder="請輸入產品簡介"
                              resize="none"
                              v-model="data.introduce"
                            /> -->
                          </div>
                        </template>
                      </vxe-form>
                      <vxe-modal
                        transfer
                        v-model="mediaError"
                        width="600"
                        show-footer
                      >
                        <template #title>
                          <span>前台顯示錯誤</span>
                        </template>
                        <template #default>
                          <div class=" text-gray-600 mb-3">
                            請擷選嵌入影片 src="" 中的內容。
                          </div>
                          <div class="">
                            <img
                              class=" max-w-full h-auto"
                              src="@/assets/images/linkImg.png"
                              alt=""
                            />
                          </div>
                        </template>
                      </vxe-modal>
                    </div>

                    <div
                      id="productContent"
                      class="tab-content__pane p-3 border border-theme-5"
                    >
                      <div class="mb-4">
                        <vxe-form
                          ref="introduceForm"
                          v-bind="IntroduceOptions"
                          :data="row"
                        >
                          <template #html-editer="{ data }">
                            <div>
                              產品介紹
                              <CKEditor
                                v-model="data.introduce_2"
                                :editor="classicEditor"
                                :config="ContentConfig"
                              />
                            </div>
                          </template>
                        </vxe-form>
                      </div>
                    </div>

                    <div
                      id="productImg"
                      class="tab-content__pane p-3 border border-theme-5"
                    >
                      <div class="mb-4">
                        <div class="ml-3">
                          <!-- <slider-editor
                                    ref="sliderEditor"
                                    :itemWidth="548"
                                    :itemHeight="508"
                                    /> -->
                          <vxe-form
                            ref="sliderForm"
                            v-bind="SliderOptions"
                            :data="row"
                          >
                            <template #form-banners="{ data }">
                              <div class="ml-3" v-if="showProductImg">
                                <product-slider
                                  ref="sliderEditor"
                                  :itemWidth="row.Width"
                                  :itemHeight="row.Height"
                                  :navigation="true"
                                  :width="sliderWidth"
                                  :pid="row.ProductID"
                                  :filePostAction="uploadAction"
                                  :formOptions="bannerFormOptions"
                                  :headers="headers"
                                  v-model="data.productImageIDs"
                                  @add="onSliderEdit"
                                  @edit="onSliderEdit"
                                  @save="onSliderSave"
                                  @remove="onSliderRemove"
                                />
                                <div
                                  class=" text-lg text-right mr-4 text-red-400"
                                >
                                  上傳尺寸為 620x420
                                </div>
                              </div>
                              <div class="ml-3 h-80" v-if="!showProductImg">
                                <div class="text-base">
                                  請先建立產品並儲存，才能添加產品圖片
                                </div>
                              </div>
                            </template>
                          </vxe-form>
                        </div>
                      </div>
                    </div>

                    <div
                      id="productContact"
                      class="tab-content__pane p-3 border border-theme-5"
                    >
                      <div class=" mb-6">
                        <vxe-form
                          ref="contactForm"
                          v-bind="ContactOptions"
                          :data="row"
                        >
                          <template #contact="{ data }">
                            <contact-list
                              :columnCount="4"
                              :items="contactItems"
                              v-model="data.contactAccountIDs"
                            ></contact-list>
                          </template>
                        </vxe-form>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #form-permissions="{ data }">
                <check-box-list
                  :columnCount="4"
                  :items="permissionItems"
                  v-model="data.PermissionValues"
                ></check-box-list>
              </template>
            </vxe-form>
          </template>
          <template #filter-name="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                >
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
          <template #modal-footer>
            <vxe-button
              type="submit"
              status="primary"
              content="儲存"
              @click="$refs.form.dispatchEvent('submit')"
            ></vxe-button>
            <vxe-button
              type="reset"
              content="重置"
              @click="$refs.form.dispatchEvent('reset')"
            ></vxe-button>
          </template>
          <!-- <template #modal-footer>
            <vxe-button
              status= "primary"
              @click="beforeSubmit(row, submit)"
              round
              icon="simple-icon-check"
              >確認</vxe-button
            >
            <vxe-button
              @click="$refs.form.dispatchEvent('reset')"
              round
              status="link-style"
              >重置</vxe-button
            >
          </template> -->
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
  <!-- 產品與服務管理Modal -->
  <!-- <ProductModal /> -->
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, {
  computed,
  defineComponent,
  ref,
  reactive
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import ContactList from "@/cloudfun/components/ContactList.vue";
import ProductModal from "@/components/product-modal/Main.vue";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import ProductSlider from "@/cloudfun/components/ProductSlider.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import InputBox, { InputBoxOptions } from "@/cloudfun/components/InputBox.vue";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import { onMounted } from "vue";
import router from "@/router";
import "@/global-components/ckeditor/styles.css";
import { VXETable } from "vxe-table";
export default defineComponent({
  components: {
    Grid,
    ContactList,
    ProductModal,
    ProductSlider,
    FileUploader,
    InputBox,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    var printColumns = [{ field: "Name" }, { field: "Description" }];
    const selectedTab = ref("info");
    const uploader = ref<any>({});

    const currentServices = ref<any>([]);
    const currentCategories = ref<any>([]);
    const showProductImg = ref<any>(false);
    const mediaError = ref(false);
    const categories = reactive({
      service: [],
      product: []
    });

    const user = JSON.parse(sessionStorage.getItem("EmptyNext-user")!);
    const headers = {
      Authorization: `Bearer ${user?.token}`
    };
    const imageData = { ProductID: 0 };

    const gridOptions: GridOptions = {
      id: "product",
      title: "產品",
      multiselect: false,
      toolbarConfig: {
        custom: false,
        refresh: true
      },
      printConfig: {
        sheetName: "產品",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "產品",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "no",
          title: "產品編號",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "name",
          title: "產品名稱",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "categories",
          title: "產品類別",
          type: "html",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "services",
          title: "服務類別",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "image",
          title: "產品預覽",
          type: "html",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        // {
        //   field: "previewArchiveID",
        //   title: "產品預覽",
        //   align: "center",
        //   slots: { default: "image_column" }
        // },
        {
          field: "enable",
          title: "狀態",
          align: "center",
          slots: { default: "switch" }
        },
        {
          field: "operate",
          title: "操作",
          align: "center",
          slots: { default: "operate" }
        }

        // {
        //   field: "edit",
        //   title: "操作",
        //   width: 110,
        //   fixed: "right",
        //   align: "center",
        //   showOverflow: true,
        //   sortable: false,
        //   slots: { default: "column-edit" }
        // }
      ],
      promises: {
        query: model
          ? params =>
              model.dispatch("product/query", {
                pageSize: params.pageSize,
                page: params.page
              })
          : undefined,
        queryAll: model ? () => model.dispatch("product/query") : undefined,
        save: model
          ? params => model.dispatch("product/save", params)
          : undefined,
        insert: model
          ? data => model.dispatch("product/insert", data)
          : undefined,
        delete: model ? id => model.dispatch("product/delete", id) : undefined,
        update: model
          ? data => model.dispatch("product/update", data)
          : undefined
      },
      modalConfig: { showFooter: true, width: "80%", height: "80%" }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 60,
      titleAlign: "right",
      items: [{ span: 24, slots: { default: "tab" } }],
      rules: {
        Name: [{ type: "string", required: true }],
        // categories: [{ required: true }],
        services: [{ required: true }],
        categories: [
          {
            type: "string",
            validator(el) {
              console.log("el", el);
              if (!el.itemValue || el.itemValue.length === 0)
                return new Error("請輸入案件編號");
            }
          }
        ]
      }
    };

    const InfoFormOptions = reactive({
      items: [
        {
          field: "previewArchive",
          title: "產品代表圖",
          span: 9,
          slots: { default: "column-photo-and-remark" }
        },
        {
          className: "form-col-layout",
          field: "name",
          title: "產品名稱",
          span: 15,
          itemRender: {
            name: "$input",
            props: { placeholder: "", clearable: true }
          }
        },
        {
          className: "form-col-layout",
          field: "no",
          title: "產品編號",
          span: 15,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "供應商內部管理使用，不影響平台上架",
              clearable: true
            }
          }
        },

        {
          className: "form-col-layout",
          field: "spec",
          title: "產品規格",
          span: 15,
          itemRender: {
            name: "$input",
            props: { placeholder: "", clearable: true }
          }
        },
        {
          // className: "form-col-layout",
          field: "enable",
          title: "上架狀態",
          span: 7,
          slots: { default: "column-enable" }
          // itemRender: {
          //   name: "$switch",
          //   props: { placeholder: "" }
          // }
        },
        {
          // className: "form-col-layout",
          field: "isCommonContractItem",
          title: "",
          span: 8,
          slots: { default: "column-isCommonContractItem" }
          // itemRender: {
          //   name: "$checkbox",
          //   props: { placeholder: "" }
          // }
        },
        {
          className: "form-col-layout mt-4",
          field: "url",
          title: "產品官網",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "", clearable: true }
          }
        },
        {
          // className: "form-col-layout",
          field: "mediaUrl",
          title: "",
          span: 12,
          slots: { default: "column-mediaUrl" }
          // itemRender: {
          //   name: "$input",
          //   props: { placeholder: "", clearable: true }
          // }
        },
        {
          field: "keyword",
          title: "",
          span: 24,
          required: true,
          slots: { default: "column-keyword" }
        },
        {
          field: "keyword2",
          title: "",
          span: 24,
          slots: { default: "column-keyword2" }
        },
        {
          title: "聯絡窗口",
          span: 24,
          slots: { default: "column-member" }
        },
        {
          field: "introduce",
          title: "產品簡介",
          span: 24,
          itemRender: { name: "$textarea" }
        }
        // {
        //   className: "form-col-layout",
        //   field: "introduce",
        //   title: "產品簡介",
        //   span: 24,
        //   itemRender: {
        //     name: "$input",
        //     props: { placeholder: "", clearable: true },
        //   },
        // },
      ],
      rules: {
        name: [{ required: true, message: "請輸入產品名稱" }],
        introduce: [{ required: true, message: "請輸入產品簡介" }],
        // keyword: [{ required: true, message: "請選擇服務類別" }],
        // keywor2: [{ required: true, message: "請選擇產品類別" }],
        // categories: [{ required: true }],
        // services: [{ required: true }],
        url: [
          {
            type: "string",
            max: 2000,
            pattern: new RegExp(
              "(https?:\\/\\/[\\w-\\.]+(:\\d+)?(\\/[~\\w\\/\\.]*)?(\\?\\S*)?(#\\S*)?)"
            )
          }
        ],
        mediaUrl: [
          {
            type: "string",
            max: 2000,
            pattern: new RegExp(
              "(https?:\\/\\/[\\w-\\.]+(:\\d+)?(\\/[~\\w\\/\\.]*)?(\\?\\S*)?(#\\S*)?)"
            )
          }
        ]
      }
    });

    const IntroduceOptions = reactive({
      items: [{ span: 24, slots: { default: "html-editer" } }]
      // rules: {
      //   introduce_2: [{ required: true }]
      // }
    });

    const SliderOptions = reactive({
      items: [{ span: 24, slots: { default: "form-banners" } }],
      rules: {}
    });

    const ContactOptions = reactive({
      items: [{ span: 24, slots: { default: "contact" } }],
      rules: {}
    });

    const contactItems = computed(() => {
      const items: any[] = [];
      if (positionList.value) {
        positionList.value.forEach((e: any) => {
          if (e.value != 1) {
            const member = members.value
              ? members.value
                  .filter((i: any) => i.position == e.value)
                  .map((j: any) => {
                    return { name: j.name, value: j.memberID };
                  })
              : [];
            items.push({ name: e.text, items: member, order: e.value });
          }
        });
      }
      return items.sort((a: any, b: any) => a.order - b.order);
    });

    const classicEditor = ClassicEditor;
    const ContentConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "imageInsert",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "mediaEmbed",
          // 'codeBlock',
          "|",
          "|",
          "undo",
          "redo"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    };

    const initDocumentEditor = (editor: any) => {
      const toolbarContainer = document.querySelector(
        ".document-editor__toolbar"
      );
      if (toolbarContainer)
        toolbarContainer.appendChild(editor.ui.view.toolbar.element);
      const win: any = window;
      win.editor = editor;
    };

    const insertProdict = (row: any, submitFunc: any) => {
      // row.services = ['測試1', '測試2']
      // row.categories = ['測試3', '測試4']
      //row.productImageIDs = []
      //row.contactAccountIDs = []
      //console.log(row)
      if (!row.services || row.services.length == 0) row.services = [];
      if (!row.categories || row.categories.length == 0) row.categories = [];
      if (!row.no) row.no = "";
      if (!row.previewArchiveID) row.previewArchiveID = "";
      submitFunc();
      // model?.dispatch('product/insert', row).then(response => {
      //   console.log(response)
      //   grid.value.refresh()
      // 	// if(response.status) {
      // 	// 	return response.payload;
      // 	// } else {
      // 	// 	CloudFun.send("error", {
      // 	// 	subject: "新增失敗",
      // 	// 	content: response.message,
      // 	// 	});
      // 	// }
      // }).catch((error) => {
      // 	CloudFun.send("error", {
      // 		subject: "新增失敗",
      // 		content: error.message,
      // 	});
      // });
    };

    // const servicesSelectorOptions: InputBoxOptions = {
    //   rowId: "id",
    //   transfer: true,
    //   placeholder: "請輸入產品類別",
    //   textField: "name",
    //   valueField: "id",
    //   addIfNotExists: true,
    //   columns: [
    //     {
    //       field: "name",
    //       title: "名稱",
    //       showHeaderOverflow: true,
    //       showOverflow: true,
    //       sortable: true
    //     }
    //   ],
    //   multiselect: true,
    //   showHeader: true,
    //   promises: {
    //     find: value =>
    //       new Promise((resolve, reject) => {
    //         resolve(currentCategories.value);
    //       }), // eslint-disable-line
    //     query: params => new Promise((resolve, reject) => {}),
    //     insert: value => {
    //       var data = value.name;
    //       return new Promise((resolve, reject) => {
    //         resolve({ id: data, name: data });
    //       });
    //     }
    //   }
    // };

    const servicesSelectorOptions: SelectBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "選擇",
      textField: "text",
      valueField: "value",
      addIfNotExists: false,
      columns: [
        {
          field: "text",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      pagerConfig: { pageSize: 100 },
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            console.log("value", value);
            console.log(
              "🚀 ~ file: Main.vue ~ line 1140 ~ newPromise ~ service",
              categories
            );
            const el: any = categories.service.find(
              (item: any) => item.value === value
            );
            console.log("el", el);
            if (el) resolve({ id: el.value, text: el.text, value: el.value });
          }), // eslint-disable-line
        query: params =>
          new Promise((resolve, reject) => {
            const payload = {
              data: categories.service,
              intialPage: 1,
              page: 0,
              pageSize: categories.service.length,
              totalCount: categories.service.length,
              pageCount: 0
            };
            resolve(payload);
            // model!.dispatch("system/serviceCategories").then(result => {
            //   console.log("service payload", result.payload.Service_Categories);
            //   const payload = {
            //     data: result.payload.Service_Categories,
            //     intialPage: 1,
            //     page: 0,
            //     pageSize: result.payload.Service_Categories.length,
            //     totalCount: result.payload.Service_Categories.length,
            //     pageCount: 0
            //   };
            //   resolve(payload);
            // });
          })
        // query: params =>
        //   model!.dispatch("system/serviceCategories", {
        //     pageSize: params.pageSize,
        //     page: params.page,
        //     search: "null"
        //   }) // eslint-disable-line
      }
    };

    const categorySelectorOptions: SelectBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "選擇",
      textField: "text",
      valueField: "value",
      addIfNotExists: false,
      columns: [
        {
          field: "text",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      pagerConfig: { pageSize: 100 },
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            const el: any = categories.product.find(
              (item: any) => item.value === value
            );
            console.log("el", el);
            if (el) resolve({ id: el.value, text: el.text, value: el.value });
          }), // eslint-disable-line
        query: params =>
          new Promise((resolve, reject) => {
            // model!.dispatch("system/productCategories").then(result => {
            //   console.log("service payload", result.payload.Product_Categories);
            const payload = {
              data: categories.product,
              intialPage: 1,
              page: 0,
              pageSize: categories.product.length,
              totalCount: categories.product.length,
              pageCount: 0
            };
            resolve(payload);
            // });
          })
        // query: params =>
        //   model!.dispatch("system/serviceCategories", {
        //     pageSize: params.pageSize,
        //     page: params.page,
        //     search: "null"
        //   }) // eslint-disable-line
      }
    };

    // const categorySelectorOptions: InputBoxOptions = {
    //   rowId: "id",
    //   transfer: true,
    //   placeholder: "請輸入服務類別",
    //   textField: "name",
    //   valueField: "id",
    //   addIfNotExists: true,
    //   columns: [
    //     {
    //       field: "name",
    //       title: "名稱",
    //       showHeaderOverflow: true,
    //       showOverflow: true,
    //       sortable: true
    //     }
    //   ],
    //   multiselect: true,
    //   showHeader: true,
    //   promises: {
    //     find: value =>
    //       new Promise((resolve, reject) => {
    //         resolve(currentServices.value);
    //       }), // eslint-disable-line
    //     query: params => new Promise((resolve, reject) => {}),
    //     insert: value => {
    //       var data = value.name;
    //       return new Promise((resolve, reject) => {
    //         resolve({ id: data, name: data });
    //       });
    //     }
    //   }
    // };

    const memberSelectorOptions: SelectBoxOptions = {
      rowId: "memberID",
      transfer: true,
      placeholder: "選擇",
      textField: "name",
      valueField: "memberID",
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value => model!.dispatch("member/find", value), // eslint-disable-line
        query: params =>
          model!.dispatch("member/query", {
            pageSize: params.pageSize,
            page: params.page,
            search: "null"
          }) // eslint-disable-line
      }
    };

    const sliderWidth = ref("700px");
    const bannerFormOptions = {
      titleWidth: 80,
      titleAlign: "right",
      items: [
        // {
        //   field: "width",
        //   title: "寬度",
        //   span: 24,
        //   itemRender: {
        //     name: "$input",
        //     props: {
        //       type: "number",
        //       placeholder: "請輸入網址",
        //       clearable: true
        //     }
        //   }
        // },
        // {
        //   field: "height",
        //   title: "高度",
        //   span: 24,
        //   itemRender: {
        //     name: "$input",
        //     props: {
        //       type: "number",
        //       placeholder: "請輸入文字",
        //       clearable: true
        //     }
        //   }
        // }
      ],
      rules: {}
    };

    const sliderEditor = ref<any>({});
    const info = ref<any>({});
    const auth = ref<any>({});

    const getInfo = () => {
      return model
        ?.dispatch("user/find")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getMembers = () => {
      return model
        ?.dispatch("member/query", { pageSize: 999, page: 1, search: "null" })
        .then(response => {
          if (response) {
            return response.data;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const positionList = ref<any>([]);
    const members = ref<any>([]);

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    onMounted(async () => {
      auth.value = await getAuth();
      if (!auth.value.isBuyer) router.push("/company-not-vendor");
      info.value = await getInfo();
      //console.log(info.value);
      var positionData = await model?.dispatch("option/find", [
        "Vendor_Position"
      ]);
      if (positionData) {
        positionList.value = positionData.Vendor_Position;
        members.value = await getMembers();
      }
      var serviceCategoriesData = await model?.dispatch("option/find", [
        "Service_Categories"
      ]);

      if (serviceCategoriesData) {
        categories.service = serviceCategoriesData.Service_Categories;
      }

      var productCategoriesData = await model?.dispatch("option/find", [
        "Product_Categories"
      ]);

      if (productCategoriesData) {
        categories.product = productCategoriesData.Product_Categories;
      }
    });

    return {
      grid,
      initDocumentEditor,
      gridOptions,
      formOptions,
      contactItems,
      selectedTab,
      InfoFormOptions,
      IntroduceOptions,
      ContentConfig,
      classicEditor,
      uploader,
      insertProdict,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`,
      headers,
      imageData,
      servicesSelectorOptions,
      categorySelectorOptions,
      currentServices,
      currentCategories,
      bannerFormOptions,
      sliderWidth,
      SliderOptions,
      sliderEditor,
      showProductImg,
      memberSelectorOptions,
      info,
      ContactOptions,
      auth,
      mediaError,
      categories
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    beforeSubmit(row: any, submitFunc: any) {
      console.log("🚀 ~ file: Main.vue:1502 ~ beforeSubmit ~ row:", row);

      let msg = "";
      if (!row.categories || row.categories.length === 0) {
        msg += "請選擇服務類別";
      }

      if (!row.services || row.services.length === 0) {
        if (msg.length > 0) msg += "\n";
        msg += "請選擇產品類別";
      }

      if (msg.length > 0) {
        VXETable.modal.alert({ content: msg, title: "錯誤", status: "error" });
        return;
      }

      this.ValidForm(row, submitFunc);
    },
    ValidForm(row: any, submitFunc: any) {
      const introduceForm: any = this.$refs.introduceForm;
      const infoForm: any = this.$refs.infoForm;

      infoForm.validate((ErrMap: any) => {
        if (!ErrMap) {
          introduceForm.validate((ErrMap: any) => {
            if (!ErrMap) {
              this.insertProdict(row, submitFunc);
            } else {
            }
          });
        } else {
        }
      });
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.productID
        ? await this.$model.dispatch("product/find", row.productID)
        : undefined;
      if (entity) {
        console.log("entity", entity);
        Object.assign(row, entity);
        if (row.previewArchiveID) {
          row.imgUri = `${this.uploadAction}/${entity.previewArchiveID}`;
          row.cloneArchiveID = row.previewArchiveID;
        }
        if (!row.introduce_2) row.introduce_2 = "";
        if (row.services && row.services.length) {
          this.currentServices = row.services.map((el: any) => {
            return { id: el, text: el, value: el };
          });
        }
        if (row.categories && row.categories.length) {
          this.currentCategories = row.categories.map((el: any) => {
            return { id: el, text: el, value: el };
          });
        }
        this.showProductImg = true;
      }
      callback();
    },
    async updateEnable(row: any) {
      const entity = row.productID
        ? await this.$model.dispatch("product/find", row.productID)
        : undefined;
      if (entity) {
        entity.enable = row.enable;
        entity.productID = row.productID;

        this.$model
          .dispatch("product/update", entity)
          .then(() => {
            this.grid.refresh();
          })
          .catch(error => {
            CloudFun.send("error", {
              subject: "更新失敗",
              content: error.message
            });
          });
      }
    },
    inputFile: function(newFile: any, oldFile: any) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        console.log("newFile response", newFile.response);
        if (newFile.xhr) {
          console.log("status", newFile.xhr.status);
        }
      }
    },
    // imageupdate: function(value: any) {
    //   console.log(value)
    //   //data.previewArchiveID = value?.archiveID;
    // }
    onSliderEdit(image: any, callback: any) {
      image.PositionId = this.grid.editingRow.Id;
      callback();
    },
    async onSliderRemove(image: any, callback: any) {
      await this.$model.dispatch("banner/delete", image.Id);
      callback();
    },
    async onSliderSave(params: any, callback: any) {
      //var ref = this.sliderEditor;
      //console.log('ref', ref.reload)
      //ref.reload()
      // await this.$model.dispatch('banner/save', params).then(
      //   () => {
      //     this.$model.dispatch('bannerPosition/find', this.grid.editingRow.Id).then(
      //       (entity) => {
      //         //this.$refs.sliderEditor.reload(entity.Banners)
      //       },
      //       reason => { CloudFun.send('error', { subject: '重載失敗', content: reason }) }
      //     )
      //   },
      //   reason => { CloudFun.send('error', { subject: '保存失敗', content: reason }) }
      // )
      callback();
    }
  }
});
</script>

<style lang="scss">
.vxe-cell {
  text-align: center;
}
</style>
