<template>
  <div
    id="product-modal"
    class="modal flex justify-center items-center pointer-events-none"
  >
    <div
      class="modal__content modal__content--xl max-h-screen overflow-y-scroll sm:overflow-y-visible relative w-screen p-6 max-w-screen-lg pointer-events-auto"
    >
      <a
        data-dismiss="modal"
        href="javascript:;"
        class="absolute right-0 top-0 mt-6 mr-6"
      >
        <xIcon class="w-8 h-8 text-gray-500" />
      </a>

      <div
        class="nav-tabs flex flex-col sm:flex-row sm:items-center  mr-16 pb-1"
      >
        <a
          data-toggle="tab"
          data-target="#productInfo"
          href="javascript:;"
          class="mb-2 mx-2 sm:pb-3 active"
        >
          產品資訊
        </a>
        <a
          data-toggle="tab"
          data-target="#productContent"
          href="javascript:;"
          class="mb-2 mx-2 sm:pb-3 "
        >
          產品介紹
        </a>
        <a
          data-toggle="tab"
          data-target="#productImg"
          href="javascript:;"
          class="mb-2 mx-2 sm:pb-3 "
        >
          產品圖片
        </a>
        <a
          data-toggle="tab"
          data-target="#productContact"
          href="javascript:;"
          class="mb-2 mx-2 sm:pb-3 "
        >
          指定聯絡窗口
        </a>
      </div>

      <div class="tab-content">
        <div
          id="productInfo"
          class="tab-content__pane p-3 border border-theme-5 active"
        >
          <div class=" lg:grid grid-cols-3 gap-5 ">
            <div class=" col-span-1 mb-4">
              <div class="flex flex-col sm:flex-row mb-2">
                <div class="w-full sm:w-24 sm:text-right sm:mr-3">
                  <span class=" text-red-400">＊</span>產品代表圖
                </div>
                <div class="">
                  <a class="w-9.375 h-9.375 relative" href="#">
                    <span
                      class=" absolute left-0 ring-0 -bottom-36 h-5 w-9.375 text-center z-10"
                      >上傳圖片</span
                    >
                    <img
                      class=" max-w-full h-auto"
                      src="@/midone/assets/images/placeholders/200x200.jpg"
                      style=" width:150px"
                      alt="產品代表圖"
                    />
                  </a>
                </div>
              </div>
              <div class="flex flex-col sm:flex-row my-2">
                <div class="w-full sm:w-24 sm:text-right sm:mr-3">上架狀態</div>
                <div class=" flex justify-start items-center text-sm">
                  上架
                  <input
                    type="checkbox"
                    class="input input--switch border mx-3"
                    checked
                  />
                  下架
                </div>
              </div>
            </div>
            <div class=" col-span-2">
              <div class="flex flex-col md:flex-row items-center mb-4">
                <label class="w-full md:w-20 md:text-right md:mr-3"
                  >><span class=" text-red-400">＊</span>產品編號</label
                >
                <input
                  type="text"
                  class="input w-full border flex-1"
                  placeholder="非必填;用於供應商 內部管理使用之代號，不影響平台上架"
                />
              </div>
              <div class="flex flex-col md:flex-row items-center mb-4">
                <label class="w-full md:w-20 md:text-right md:mr-3"
                  ><span class=" text-red-400">＊</span>產品名稱</label
                >
                <input
                  type="text"
                  class="input w-full border flex-1"
                  placeholder="20個中文字"
                />
              </div>
              <div class="flex flex-col md:flex-row items-center mb-4">
                <label class="w-full md:w-20 md:text-right md:mr-3"
                  ><span class=" text-red-400">＊</span>服務類別</label
                >
                <TailSelect
                  v-model="select"
                  :options="{
                    classNames:
                      'w-full md:w-40 md:mr-2 !mb-2 md:!mb-0 flex-1 text-sm'
                  }"
                >
                  <option value="0">請選擇</option>
                  <option value="2">服務類別</option>
                </TailSelect>

                <TailSelect
                  v-model="select"
                  :options="{
                    classNames:
                      'w-full md:w-40 md:mr-2 !mb-2 md:!mb-0 flex-1 text-sm'
                  }"
                >
                  <option value="0">請選擇</option>
                  <option value="2">服務類別</option>
                </TailSelect>
                <button
                  type="button"
                  class="ml-auto md:m-0 w-9 h-9 border rounded-md text-lg text-gray-500 flex justify-center items-center"
                >
                  <PlusIcon />
                </button>
              </div>
              <div class="flex flex-col md:flex-row items-center mb-4">
                <label class="w-full md:w-20 md:text-right md:mr-3"
                  ><span class=" text-red-400">＊</span>產品類別</label
                >
                <TailSelect
                  v-model="select"
                  :options="{
                    classNames:
                      'w-full md:w-40 md:mr-2 !mb-2 md:!mb-0 flex-1 text-sm'
                  }"
                >
                  <option value="0">請選擇</option>
                  <option value="2">服務類別</option>
                </TailSelect>

                <TailSelect
                  v-model="select"
                  :options="{
                    classNames:
                      'w-full md:w-40 md:mr-2 !mb-2 md:!mb-0 flex-1 text-sm'
                  }"
                >
                  <option value="0">請選擇</option>
                  <option value="2">服務類別</option>
                </TailSelect>
                <button
                  type="button"
                  class="ml-auto md:m-0 w-9 h-9 border rounded-md text-lg text-gray-500 flex justify-center items-center"
                >
                  <PlusIcon />
                </button>
              </div>
            </div>
            <div class=" col-span-3">
              <div class="flex flex-col md:flex-row mb-4 lg:mb-0">
                <label class="w-full md:w-24 md:text-right md:mr-3"
                  >產品簡介</label
                >
                <textarea
                  class="input w-full border flex-1"
                  placeholder="詳細產品文字說明"
                ></textarea>
              </div>
            </div>
            <div class=" col-span-3">
              <div class="flex flex-col md:flex-row mb-4 lg:mb-0">
                <label class="w-full md:w-24 md:text-right md:mr-3"
                  >產品簡介影音</label
                >
                <input
                  type="text"
                  class="input w-full border flex-1"
                  placeholder="產品影音嵌入網址"
                />
              </div>
            </div>
            <div class=" col-span-3">
              <div class="flex flex-col md:flex-row mb-4">
                <label class="w-full md:w-24 md:text-right md:mr-3"
                  >更多產品簡介</label
                >
                <input
                  type="text"
                  class="input w-full border flex-1"
                  placeholder="詳細產品簡介網址"
                />
              </div>
            </div>
          </div>
          <div class=" flex justify-between items-center">
            <button
              type="button"
              class="button text-center w-10 h-10 rounded-full text-gray-600 bg-gray-200 mb-2 xl:mb-0 hover:text-white hover:bg-gray-600"
            >
              <Trash2Icon class="w-4 h-4 mx-auto" />
            </button>
            <button
              type="button"
              class="button flex justify-center items-center button--lg w-32 text-white bg-theme-1 align-top mb-2 xl:mb-0"
            >
              <CheckIcon class="mr-2 w-4 h-4" />
              儲存
            </button>
          </div>
        </div>

        <div
          id="productContent"
          class="tab-content__pane p-3 border border-theme-5"
        >
          <div class="mb-4">
            <CKEditor
              v-model="standardEditorData"
              :editor="classicEditor"
              :config="editorConfig"
            />
          </div>
          <div class=" flex justify-end items-center">
            <button
              type="button"
              class="button flex justify-center items-center button--lg w-32 text-white bg-theme-1 align-top mb-2 xl:mb-0"
            >
              <CheckIcon class="mr-2 w-4 h-4" />
              儲存
            </button>
          </div>
        </div>

        <div
          id="productImg"
          class="tab-content__pane p-3 border border-theme-5"
        >
          <div class="mb-4">
            <div class="ml-3">
              <slider-editor
                ref="sliderEditor"
                :itemWidth="548"
                :itemHeight="508"
              />
            </div>
          </div>
          <div class=" flex justify-end items-center">
            <button
              type="button"
              class="button flex justify-center items-center button--lg w-32 text-white bg-theme-1 align-top mb-2 xl:mb-0"
            >
              <CheckIcon class="mr-2 w-4 h-4" />
              儲存
            </button>
          </div>
        </div>

        <div
          id="productContact"
          class="tab-content__pane p-3 border border-theme-5"
        >
          <div class=" mb-6">
            <div
              class=" flex justify-between items-center pb-3 border-b border-gray-500 mb-4"
            >
              特助
              <div
                class="flex items-center text-gray-700 dark:text-gray-500 mt-2"
              >
                <input
                  id="select-all"
                  type="checkbox"
                  class="input border mr-2"
                />
                <label class="cursor-pointer select-none" for="select-all"
                  >全選</label
                >
              </div>
            </div>
            <div class=" md:grid grid-cols-3 gap-10">
              <button type="button" class="button contact-button active">
                王ＸＸ-Bryan-特別助理
              </button>
            </div>
          </div>
          <div class=" mb-6">
            <div
              class=" flex justify-between items-center pb-3 border-b border-gray-500 mb-4"
            >
              業務
              <div
                class="flex items-center text-gray-700 dark:text-gray-500 mt-2"
              >
                <input
                  id="select-all-seller"
                  type="checkbox"
                  class="input border mr-2"
                />
                <label
                  class="cursor-pointer select-none"
                  for="select-all-seller"
                  >全選</label
                >
              </div>
            </div>
            <div class=" md:grid grid-cols-3 gap-10">
              <button type="button" class="button contact-button">
                陳ＸＸ-小熊-業務助理
              </button>
              <button type="button" class="button contact-button active">
                黃ＸＸ-Eric-業務員
              </button>
              <button type="button" class="button contact-button active">
                張ＸＸ-小胖-業務助理
              </button>
              <button type="button" class="button contact-button">
                林ＸＸ-Aaron-業務員
              </button>
            </div>
          </div>
          <div class=" flex justify-end items-center">
            <button
              type="button"
              class="button flex justify-center items-center button--lg w-32 text-white bg-theme-1 align-top mb-2 xl:mb-0"
            >
              <CheckIcon class="mr-2 w-4 h-4" />
              儲存
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import SliderEditor from "@/cloudfun/components/SliderEditor.vue";

export default defineComponent({
  components: {
    SliderEditor
  },
  setup() {
    const date = ref("");
    const classicEditor = ClassicEditor;
    const simpleEditorConfig = {
      removePlugins: ["Markdown", "MediaEmbedToolbar", "ImageToolbar"],
      toolbar: {
        items: ["bold", "italic", "underline", "link"]
      }
    };
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin],
      removePlugins: ["Markdown", "MediaEmbedToolbar"],
      toolbar: {
        items: [
          "fontSize",
          "fontFamily",
          "fontColor",
          "fontBackgroundColor",
          "bold",
          "italic",
          "underline",
          // 'strikethrough',
          //   'code',
          // 'subscript',
          // 'superscript',
          "link",
          "undo",
          "redo",
          "imageUpload",
          "highlight"
        ]
      },
      image: {
        toolbar: ["imageTextAlternative", "imageStyle:side", "linkImage"]
      }
    };
    const standardEditorData = ref("<p>Content of the editor.</p>");
    const initDocumentEditor = (editor: any) => {
      const toolbarContainer = document.querySelector(
        ".document-editor__toolbar"
      );
      if (toolbarContainer)
        toolbarContainer.appendChild(editor.ui.view.toolbar.element);
      const win: any = window;
      win.editor = editor;
    };

    const sliderWidth = ref("100%");
    const bannerFormOptions = {
      titleWidth: 80,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true }
          }
        },
        {
          field: "LinkUrl",
          title: "鏈結網址",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入網址", clearable: true }
          }
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", max: 128 }],
        LinkUrl: [{ type: "string", max: 2000 }],
        Remark: [{ type: "string", max: 512 }]
      }
    };

    return {
      date,
      classicEditor,
      simpleEditorConfig,
      editorConfig,
      standardEditorData,
      initDocumentEditor,
      sliderWidth,
      bannerFormOptions
    };
  }
});
</script>
