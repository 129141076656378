
/* eslint-disable */
import CloudFun, {
  computed,
  defineComponent,
  ref,
  reactive
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import ContactList from "@/cloudfun/components/ContactList.vue";
import ProductModal from "@/components/product-modal/Main.vue";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import ProductSlider from "@/cloudfun/components/ProductSlider.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import InputBox, { InputBoxOptions } from "@/cloudfun/components/InputBox.vue";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import { onMounted } from "vue";
import router from "@/router";
import "@/global-components/ckeditor/styles.css";
import { VXETable } from "vxe-table";
export default defineComponent({
  components: {
    Grid,
    ContactList,
    ProductModal,
    ProductSlider,
    FileUploader,
    InputBox,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    var printColumns = [{ field: "Name" }, { field: "Description" }];
    const selectedTab = ref("info");
    const uploader = ref<any>({});

    const currentServices = ref<any>([]);
    const currentCategories = ref<any>([]);
    const showProductImg = ref<any>(false);
    const mediaError = ref(false);
    const categories = reactive({
      service: [],
      product: []
    });

    const user = JSON.parse(sessionStorage.getItem("EmptyNext-user")!);
    const headers = {
      Authorization: `Bearer ${user?.token}`
    };
    const imageData = { ProductID: 0 };

    const gridOptions: GridOptions = {
      id: "product",
      title: "產品",
      multiselect: false,
      toolbarConfig: {
        custom: false,
        refresh: true
      },
      printConfig: {
        sheetName: "產品",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "產品",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "no",
          title: "產品編號",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "name",
          title: "產品名稱",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "categories",
          title: "產品類別",
          type: "html",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "services",
          title: "服務類別",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        {
          field: "image",
          title: "產品預覽",
          type: "html",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        // {
        //   field: "previewArchiveID",
        //   title: "產品預覽",
        //   align: "center",
        //   slots: { default: "image_column" }
        // },
        {
          field: "enable",
          title: "狀態",
          align: "center",
          slots: { default: "switch" }
        },
        {
          field: "operate",
          title: "操作",
          align: "center",
          slots: { default: "operate" }
        }

        // {
        //   field: "edit",
        //   title: "操作",
        //   width: 110,
        //   fixed: "right",
        //   align: "center",
        //   showOverflow: true,
        //   sortable: false,
        //   slots: { default: "column-edit" }
        // }
      ],
      promises: {
        query: model
          ? params =>
              model.dispatch("product/query", {
                pageSize: params.pageSize,
                page: params.page
              })
          : undefined,
        queryAll: model ? () => model.dispatch("product/query") : undefined,
        save: model
          ? params => model.dispatch("product/save", params)
          : undefined,
        insert: model
          ? data => model.dispatch("product/insert", data)
          : undefined,
        delete: model ? id => model.dispatch("product/delete", id) : undefined,
        update: model
          ? data => model.dispatch("product/update", data)
          : undefined
      },
      modalConfig: { showFooter: true, width: "80%", height: "80%" }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 60,
      titleAlign: "right",
      items: [{ span: 24, slots: { default: "tab" } }],
      rules: {
        Name: [{ type: "string", required: true }],
        // categories: [{ required: true }],
        services: [{ required: true }],
        categories: [
          {
            type: "string",
            validator(el) {
              console.log("el", el);
              if (!el.itemValue || el.itemValue.length === 0)
                return new Error("請輸入案件編號");
            }
          }
        ]
      }
    };

    const InfoFormOptions = reactive({
      items: [
        {
          field: "previewArchive",
          title: "產品代表圖",
          span: 9,
          slots: { default: "column-photo-and-remark" }
        },
        {
          className: "form-col-layout",
          field: "name",
          title: "產品名稱",
          span: 15,
          itemRender: {
            name: "$input",
            props: { placeholder: "", clearable: true }
          }
        },
        {
          className: "form-col-layout",
          field: "no",
          title: "產品編號",
          span: 15,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "供應商內部管理使用，不影響平台上架",
              clearable: true
            }
          }
        },

        {
          className: "form-col-layout",
          field: "spec",
          title: "產品規格",
          span: 15,
          itemRender: {
            name: "$input",
            props: { placeholder: "", clearable: true }
          }
        },
        {
          // className: "form-col-layout",
          field: "enable",
          title: "上架狀態",
          span: 7,
          slots: { default: "column-enable" }
          // itemRender: {
          //   name: "$switch",
          //   props: { placeholder: "" }
          // }
        },
        {
          // className: "form-col-layout",
          field: "isCommonContractItem",
          title: "",
          span: 8,
          slots: { default: "column-isCommonContractItem" }
          // itemRender: {
          //   name: "$checkbox",
          //   props: { placeholder: "" }
          // }
        },
        {
          className: "form-col-layout mt-4",
          field: "url",
          title: "產品官網",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "", clearable: true }
          }
        },
        {
          // className: "form-col-layout",
          field: "mediaUrl",
          title: "",
          span: 12,
          slots: { default: "column-mediaUrl" }
          // itemRender: {
          //   name: "$input",
          //   props: { placeholder: "", clearable: true }
          // }
        },
        {
          field: "keyword",
          title: "",
          span: 24,
          required: true,
          slots: { default: "column-keyword" }
        },
        {
          field: "keyword2",
          title: "",
          span: 24,
          slots: { default: "column-keyword2" }
        },
        {
          title: "聯絡窗口",
          span: 24,
          slots: { default: "column-member" }
        },
        {
          field: "introduce",
          title: "產品簡介",
          span: 24,
          itemRender: { name: "$textarea" }
        }
        // {
        //   className: "form-col-layout",
        //   field: "introduce",
        //   title: "產品簡介",
        //   span: 24,
        //   itemRender: {
        //     name: "$input",
        //     props: { placeholder: "", clearable: true },
        //   },
        // },
      ],
      rules: {
        name: [{ required: true, message: "請輸入產品名稱" }],
        introduce: [{ required: true, message: "請輸入產品簡介" }],
        // keyword: [{ required: true, message: "請選擇服務類別" }],
        // keywor2: [{ required: true, message: "請選擇產品類別" }],
        // categories: [{ required: true }],
        // services: [{ required: true }],
        url: [
          {
            type: "string",
            max: 2000,
            pattern: new RegExp(
              "(https?:\\/\\/[\\w-\\.]+(:\\d+)?(\\/[~\\w\\/\\.]*)?(\\?\\S*)?(#\\S*)?)"
            )
          }
        ],
        mediaUrl: [
          {
            type: "string",
            max: 2000,
            pattern: new RegExp(
              "(https?:\\/\\/[\\w-\\.]+(:\\d+)?(\\/[~\\w\\/\\.]*)?(\\?\\S*)?(#\\S*)?)"
            )
          }
        ]
      }
    });

    const IntroduceOptions = reactive({
      items: [{ span: 24, slots: { default: "html-editer" } }]
      // rules: {
      //   introduce_2: [{ required: true }]
      // }
    });

    const SliderOptions = reactive({
      items: [{ span: 24, slots: { default: "form-banners" } }],
      rules: {}
    });

    const ContactOptions = reactive({
      items: [{ span: 24, slots: { default: "contact" } }],
      rules: {}
    });

    const contactItems = computed(() => {
      const items: any[] = [];
      if (positionList.value) {
        positionList.value.forEach((e: any) => {
          if (e.value != 1) {
            const member = members.value
              ? members.value
                  .filter((i: any) => i.position == e.value)
                  .map((j: any) => {
                    return { name: j.name, value: j.memberID };
                  })
              : [];
            items.push({ name: e.text, items: member, order: e.value });
          }
        });
      }
      return items.sort((a: any, b: any) => a.order - b.order);
    });

    const classicEditor = ClassicEditor;
    const ContentConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "imageInsert",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "mediaEmbed",
          // 'codeBlock',
          "|",
          "|",
          "undo",
          "redo"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    };

    const initDocumentEditor = (editor: any) => {
      const toolbarContainer = document.querySelector(
        ".document-editor__toolbar"
      );
      if (toolbarContainer)
        toolbarContainer.appendChild(editor.ui.view.toolbar.element);
      const win: any = window;
      win.editor = editor;
    };

    const insertProdict = (row: any, submitFunc: any) => {
      // row.services = ['測試1', '測試2']
      // row.categories = ['測試3', '測試4']
      //row.productImageIDs = []
      //row.contactAccountIDs = []
      //console.log(row)
      if (!row.services || row.services.length == 0) row.services = [];
      if (!row.categories || row.categories.length == 0) row.categories = [];
      if (!row.no) row.no = "";
      if (!row.previewArchiveID) row.previewArchiveID = "";
      submitFunc();
      // model?.dispatch('product/insert', row).then(response => {
      //   console.log(response)
      //   grid.value.refresh()
      // 	// if(response.status) {
      // 	// 	return response.payload;
      // 	// } else {
      // 	// 	CloudFun.send("error", {
      // 	// 	subject: "新增失敗",
      // 	// 	content: response.message,
      // 	// 	});
      // 	// }
      // }).catch((error) => {
      // 	CloudFun.send("error", {
      // 		subject: "新增失敗",
      // 		content: error.message,
      // 	});
      // });
    };

    // const servicesSelectorOptions: InputBoxOptions = {
    //   rowId: "id",
    //   transfer: true,
    //   placeholder: "請輸入產品類別",
    //   textField: "name",
    //   valueField: "id",
    //   addIfNotExists: true,
    //   columns: [
    //     {
    //       field: "name",
    //       title: "名稱",
    //       showHeaderOverflow: true,
    //       showOverflow: true,
    //       sortable: true
    //     }
    //   ],
    //   multiselect: true,
    //   showHeader: true,
    //   promises: {
    //     find: value =>
    //       new Promise((resolve, reject) => {
    //         resolve(currentCategories.value);
    //       }), // eslint-disable-line
    //     query: params => new Promise((resolve, reject) => {}),
    //     insert: value => {
    //       var data = value.name;
    //       return new Promise((resolve, reject) => {
    //         resolve({ id: data, name: data });
    //       });
    //     }
    //   }
    // };

    const servicesSelectorOptions: SelectBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "選擇",
      textField: "text",
      valueField: "value",
      addIfNotExists: false,
      columns: [
        {
          field: "text",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      pagerConfig: { pageSize: 100 },
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            console.log("value", value);
            console.log(
              "🚀 ~ file: Main.vue ~ line 1140 ~ newPromise ~ service",
              categories
            );
            const el: any = categories.service.find(
              (item: any) => item.value === value
            );
            console.log("el", el);
            if (el) resolve({ id: el.value, text: el.text, value: el.value });
          }), // eslint-disable-line
        query: params =>
          new Promise((resolve, reject) => {
            const payload = {
              data: categories.service,
              intialPage: 1,
              page: 0,
              pageSize: categories.service.length,
              totalCount: categories.service.length,
              pageCount: 0
            };
            resolve(payload);
            // model!.dispatch("system/serviceCategories").then(result => {
            //   console.log("service payload", result.payload.Service_Categories);
            //   const payload = {
            //     data: result.payload.Service_Categories,
            //     intialPage: 1,
            //     page: 0,
            //     pageSize: result.payload.Service_Categories.length,
            //     totalCount: result.payload.Service_Categories.length,
            //     pageCount: 0
            //   };
            //   resolve(payload);
            // });
          })
        // query: params =>
        //   model!.dispatch("system/serviceCategories", {
        //     pageSize: params.pageSize,
        //     page: params.page,
        //     search: "null"
        //   }) // eslint-disable-line
      }
    };

    const categorySelectorOptions: SelectBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "選擇",
      textField: "text",
      valueField: "value",
      addIfNotExists: false,
      columns: [
        {
          field: "text",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      pagerConfig: { pageSize: 100 },
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            const el: any = categories.product.find(
              (item: any) => item.value === value
            );
            console.log("el", el);
            if (el) resolve({ id: el.value, text: el.text, value: el.value });
          }), // eslint-disable-line
        query: params =>
          new Promise((resolve, reject) => {
            // model!.dispatch("system/productCategories").then(result => {
            //   console.log("service payload", result.payload.Product_Categories);
            const payload = {
              data: categories.product,
              intialPage: 1,
              page: 0,
              pageSize: categories.product.length,
              totalCount: categories.product.length,
              pageCount: 0
            };
            resolve(payload);
            // });
          })
        // query: params =>
        //   model!.dispatch("system/serviceCategories", {
        //     pageSize: params.pageSize,
        //     page: params.page,
        //     search: "null"
        //   }) // eslint-disable-line
      }
    };

    // const categorySelectorOptions: InputBoxOptions = {
    //   rowId: "id",
    //   transfer: true,
    //   placeholder: "請輸入服務類別",
    //   textField: "name",
    //   valueField: "id",
    //   addIfNotExists: true,
    //   columns: [
    //     {
    //       field: "name",
    //       title: "名稱",
    //       showHeaderOverflow: true,
    //       showOverflow: true,
    //       sortable: true
    //     }
    //   ],
    //   multiselect: true,
    //   showHeader: true,
    //   promises: {
    //     find: value =>
    //       new Promise((resolve, reject) => {
    //         resolve(currentServices.value);
    //       }), // eslint-disable-line
    //     query: params => new Promise((resolve, reject) => {}),
    //     insert: value => {
    //       var data = value.name;
    //       return new Promise((resolve, reject) => {
    //         resolve({ id: data, name: data });
    //       });
    //     }
    //   }
    // };

    const memberSelectorOptions: SelectBoxOptions = {
      rowId: "memberID",
      transfer: true,
      placeholder: "選擇",
      textField: "name",
      valueField: "memberID",
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value => model!.dispatch("member/find", value), // eslint-disable-line
        query: params =>
          model!.dispatch("member/query", {
            pageSize: params.pageSize,
            page: params.page,
            search: "null"
          }) // eslint-disable-line
      }
    };

    const sliderWidth = ref("700px");
    const bannerFormOptions = {
      titleWidth: 80,
      titleAlign: "right",
      items: [
        // {
        //   field: "width",
        //   title: "寬度",
        //   span: 24,
        //   itemRender: {
        //     name: "$input",
        //     props: {
        //       type: "number",
        //       placeholder: "請輸入網址",
        //       clearable: true
        //     }
        //   }
        // },
        // {
        //   field: "height",
        //   title: "高度",
        //   span: 24,
        //   itemRender: {
        //     name: "$input",
        //     props: {
        //       type: "number",
        //       placeholder: "請輸入文字",
        //       clearable: true
        //     }
        //   }
        // }
      ],
      rules: {}
    };

    const sliderEditor = ref<any>({});
    const info = ref<any>({});
    const auth = ref<any>({});

    const getInfo = () => {
      return model
        ?.dispatch("user/find")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getMembers = () => {
      return model
        ?.dispatch("member/query", { pageSize: 999, page: 1, search: "null" })
        .then(response => {
          if (response) {
            return response.data;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const positionList = ref<any>([]);
    const members = ref<any>([]);

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    onMounted(async () => {
      auth.value = await getAuth();
      if (!auth.value.isBuyer) router.push("/company-not-vendor");
      info.value = await getInfo();
      //console.log(info.value);
      var positionData = await model?.dispatch("option/find", [
        "Vendor_Position"
      ]);
      if (positionData) {
        positionList.value = positionData.Vendor_Position;
        members.value = await getMembers();
      }
      var serviceCategoriesData = await model?.dispatch("option/find", [
        "Service_Categories"
      ]);

      if (serviceCategoriesData) {
        categories.service = serviceCategoriesData.Service_Categories;
      }

      var productCategoriesData = await model?.dispatch("option/find", [
        "Product_Categories"
      ]);

      if (productCategoriesData) {
        categories.product = productCategoriesData.Product_Categories;
      }
    });

    return {
      grid,
      initDocumentEditor,
      gridOptions,
      formOptions,
      contactItems,
      selectedTab,
      InfoFormOptions,
      IntroduceOptions,
      ContentConfig,
      classicEditor,
      uploader,
      insertProdict,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`,
      headers,
      imageData,
      servicesSelectorOptions,
      categorySelectorOptions,
      currentServices,
      currentCategories,
      bannerFormOptions,
      sliderWidth,
      SliderOptions,
      sliderEditor,
      showProductImg,
      memberSelectorOptions,
      info,
      ContactOptions,
      auth,
      mediaError,
      categories
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    beforeSubmit(row: any, submitFunc: any) {
      console.log("🚀 ~ file: Main.vue:1502 ~ beforeSubmit ~ row:", row);

      let msg = "";
      if (!row.categories || row.categories.length === 0) {
        msg += "請選擇服務類別";
      }

      if (!row.services || row.services.length === 0) {
        if (msg.length > 0) msg += "\n";
        msg += "請選擇產品類別";
      }

      if (msg.length > 0) {
        VXETable.modal.alert({ content: msg, title: "錯誤", status: "error" });
        return;
      }

      this.ValidForm(row, submitFunc);
    },
    ValidForm(row: any, submitFunc: any) {
      const introduceForm: any = this.$refs.introduceForm;
      const infoForm: any = this.$refs.infoForm;

      infoForm.validate((ErrMap: any) => {
        if (!ErrMap) {
          introduceForm.validate((ErrMap: any) => {
            if (!ErrMap) {
              this.insertProdict(row, submitFunc);
            } else {
            }
          });
        } else {
        }
      });
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.productID
        ? await this.$model.dispatch("product/find", row.productID)
        : undefined;
      if (entity) {
        console.log("entity", entity);
        Object.assign(row, entity);
        if (row.previewArchiveID) {
          row.imgUri = `${this.uploadAction}/${entity.previewArchiveID}`;
          row.cloneArchiveID = row.previewArchiveID;
        }
        if (!row.introduce_2) row.introduce_2 = "";
        if (row.services && row.services.length) {
          this.currentServices = row.services.map((el: any) => {
            return { id: el, text: el, value: el };
          });
        }
        if (row.categories && row.categories.length) {
          this.currentCategories = row.categories.map((el: any) => {
            return { id: el, text: el, value: el };
          });
        }
        this.showProductImg = true;
      }
      callback();
    },
    async updateEnable(row: any) {
      const entity = row.productID
        ? await this.$model.dispatch("product/find", row.productID)
        : undefined;
      if (entity) {
        entity.enable = row.enable;
        entity.productID = row.productID;

        this.$model
          .dispatch("product/update", entity)
          .then(() => {
            this.grid.refresh();
          })
          .catch(error => {
            CloudFun.send("error", {
              subject: "更新失敗",
              content: error.message
            });
          });
      }
    },
    inputFile: function(newFile: any, oldFile: any) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        console.log("newFile response", newFile.response);
        if (newFile.xhr) {
          console.log("status", newFile.xhr.status);
        }
      }
    },
    // imageupdate: function(value: any) {
    //   console.log(value)
    //   //data.previewArchiveID = value?.archiveID;
    // }
    onSliderEdit(image: any, callback: any) {
      image.PositionId = this.grid.editingRow.Id;
      callback();
    },
    async onSliderRemove(image: any, callback: any) {
      await this.$model.dispatch("banner/delete", image.Id);
      callback();
    },
    async onSliderSave(params: any, callback: any) {
      //var ref = this.sliderEditor;
      //console.log('ref', ref.reload)
      //ref.reload()
      // await this.$model.dispatch('banner/save', params).then(
      //   () => {
      //     this.$model.dispatch('bannerPosition/find', this.grid.editingRow.Id).then(
      //       (entity) => {
      //         //this.$refs.sliderEditor.reload(entity.Banners)
      //       },
      //       reason => { CloudFun.send('error', { subject: '重載失敗', content: reason }) }
      //     )
      //   },
      //   reason => { CloudFun.send('error', { subject: '保存失敗', content: reason }) }
      // )
      callback();
    }
  }
});
